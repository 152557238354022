<template>
  <div
    :class="$style.controls"
    data-test="menu-controls"
  >
    <template v-if="userStore.userIsAuthorized">
      <button
        :class="$style.mobile"
        data-test="notifications_button_mobile"
        @click="handleNotificationsButtonClick"
      >
        <IconBell /> {{ t("header.notifications") }}
        <span
          v-if="notificationStore.hasUnreadNotifications"
          :class="$style.dot"
        />
      </button>
      <Volume
        data-test="volume_button_mobile"
        :class="$style.mobile"
      />
      <button
        data-test="profile_button"
        @click="redirect('/profile')"
      >
        <IconUser /> {{ t("header.personal_account") }}
      </button>
    </template>
    <div :class="[$style.mobile, $style.chapter]">
      {{ t("header.sections.entertainment") }}
    </div>
    <button
      :class="$style.mobile"
      data-test="cases_button_mobile"
      @click="redirect('/')"
    >
      <IconCases /> {{ t("header.cases") }}
    </button>
    <button
      :class="$style.mobile"
      data-test="games_button_mobile"
      @click="redirect('/mini-games')"
    >
      <IconGames /> {{ t("header.games") }}
    </button>
    <a
      v-if="isGenshin"
      :class="$style.mobile"
      data-test="gensgin_shop_button_mobile"
      :href="coreStore.gamelight"
    >
      <IconGem /> {{ t("shop.buy_gems") }}
    </a>
    <button
      v-if="bullpassIsAvailable"
      :class="$style.mobile"
      data-test="bullpass_button_mobile"
      @click="redirect('/event')"
    >
      <IconEvent /> Bullpass
    </button>
    <button
      v-if="exchangerEventIsEnabled"
      :class="$style.mobile"
      @click="redirect(`/${EVENT_NAME}`)"
    >
      <IconMerlin />
      {{ t(`merlin.title`) }}
    </button>
    <button
      v-if="bossBattleEnabled"
      :class="$style.mobile"
      data-test="boss_battle_button_mobile"
      @click="redirect('/boss-battle')"
    >
      <IconBossBattle />
      {{ t(`${BATTLE_KEY}.title`) }}
    </button>
    <template v-if="userStore.userIsAuthorized">
      <div :class="[$style.mobile, $style.chapter]">
        {{ t("header.sections.finance") }}
      </div>
      <button
        data-test="promocode_button"
        @click="() => promocodeStore.showPromocodePopup()"
      >
        <IconPromocode /> {{ t("header.promocode") }}
      </button>
      <button
        data-test="top_up_balance_button"
        @click="redirect('/balance/top-up')"
      >
        <IconWallet /> {{ t("header.top_up_balance") }}
      </button>
      <button
        data-test="withdraw_button"
        @click="redirect('/balance/withdrawal')"
      >
        <IconGold />{{ t("header.withdraw") }} {{ currency }}
      </button>
      <button
        :class="$style.desktop"
        data-test="referral_landing_button"
        @click="redirect('/referral-land')"
      >
        <IconReferralLand />{{ t("header.referral_landing") }}
      </button>
    </template>
    <a
      v-if="settings?.platform.is.pubg"
      :href="coreStore.gamelightPubg"
    >
      <IconGold />{{ t("header.buy_uc_cheap") }}
    </a>
    <div :class="[$style.mobile, $style.chapter]">
      {{ t("header.sections.other") }}
    </div>
    <button
      data-test="referral_landing_mobile"
      :class="$style.mobile"
      @click="redirect('/referral-land')"
    >
      <IconReferralLand /> {{ t("header.referral_landing") }}
    </button>
    <a
      :href="coreStore.tg"
      data-test="tg_button_mobile"
      target="__blank"
      :class="$style.mobile"
    >
      <IconTelegram />{{ t("header.telegram") }}
    </a>
    <a
      :href="coreStore.vk"
      data-test="vk_button_mobile"
      target="__blank"
      :class="$style.mobile"
    >
      <IconVk />{{ t("header.vkontakte") }}
    </a>
    <a
      :href="coreStore.discord"
      data-test="discrod_button_mobile"
      target="__blank"
      :class="$style.mobile"
    >
      <IconDiscord />{{ t("header.discord") }}
    </a>
    <SetLanguageDesktop
      data-test="set_language"
      :class="$style.desktop"
    />
    <SetLanguageMobile
      data-test="set_language_mobile"
      :class="$style.mobile"
      @hideMenuHeader="coreStore.hideMenuHeader"
    />
    <button
      data-test="faq_button"
      @click="redirect('/faq')"
    >
      <IconHelp /> {{ t("header.help") }}
    </button>
    <template v-if="userStore.userIsAuthorized">
      <button
        :class="$style.mobile"
        data-test="profile_settings_buttonMobile"
        @click="redirect('/user-settings')"
      >
        <IconGear />{{ t("header.profile_settings") }}
      </button>
      <button
        data-test="logout_button"
        @click="handleLogoutButtonClick"
      >
        <IconLogout /> {{ t("auth.logout") }}
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { normalize } from "~/utils/url/normalize";
import SetLanguageDesktop from "~/components/SetLanguage/SetLanguageDesktop.vue";
import SetLanguageMobile from "~/components/SetLanguage/SetLanguageMobile.vue";
import Volume from "./Controls/Volume.vue";
import { EVENT_NAME } from "~/stores/exchanger";
import { BATTLE_KEY } from "~/components/BossBattle/composables/initMainView";

const { t, locale, defaultLocale } = useI18n();
const settings = inject<{
  platform: ReturnType<typeof usePlatform>;
}>("settings");
const coreStore = useCoreStore();
const notificationStore = useNotificationsStore();
const userStore = useUserStore();
const promocodeStore = usePromocodesStore();
const currency = computed(() => {
  if (settings?.platform.is.standoff) {
    return t("header.gold");
  } else if (settings?.platform.is.genshin) {
    return t("header.gems");
  } else if (settings?.platform.is.pubg) {
    return "UC";
  } else if (settings?.platform.is.roblox) {
    return t("header.robux");
  } else if (settings?.platform.is.cs2) {
    return t("header.skins");
  } else {
    return "";
  }
});
const isGenshin = computed(() => settings?.platform.is.genshin);
const bullpassIsEnabled = useFlag("Bullpass");
const bullpassIsAvailable = computed(() => {
  const platform = usePlatform();
  if (
    bullpassIsEnabled.value &&
    platform.code !== "genshin" &&
    platform.code !== "roblox"
  ) {
    return true;
  } else {
    return false;
  }
});
const bossBattleEnabled = isDev() ? true : useFlag("boss_battle");

const redirect = (to: string) => {
  const langSuffix = locale.value === defaultLocale ? "" : locale.value;
  navigateTo(
    normalize(`/${langSuffix}/${settings?.platform.basePath || ""}/${to}`),
  );
  coreStore.hideMenuHeader();
};

const handleNotificationsButtonClick = () => {
  notificationStore.showNotificationsWindow();
  coreStore.hideMenuHeader();
};

const handleLogoutButtonClick = () => {
  userStore.logout();
  coreStore.hideMenuHeader();
};

const exchangerEventFlag = useFlag("exchanger_event");
const exchangerEventIsEnabled = computed(() => {
  const platform = usePlatform();
  if (!platform.is.genshin && !platform.is.roblox) {
    return exchangerEventFlag.value;
  } else {
    return false;
  }
});
</script>

<style lang="scss" module>
.controls {
  margin-top: 16px;
  @include safe-area-header;

  a,
  button,
  svg {
    transition: 0.25s;
  }

  a,
  button,
  .volume {
    position: relative;
    display: flex;
    align-items: center;
    width: 95%;
    margin: auto;
    padding: 13px 0px;
    padding-left: 24px;
    font-size: 0.875rem;
    color: var(--general-white);
    font-weight: 500;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    border-radius: 14px;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }

    &:hover {
      background: var(--button-ghost-active);
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: var(--general-white);
  }
}

.desktop {
  width: 95%;
  margin: auto;
  @media screen and (max-width: 1300px) {
    display: none !important;
  }
}

.mobile {
  @media screen and (min-width: 1301px) {
    display: none !important;
  }
}

.chapter {
  font-size: 12px;
  color: var(--general-white);
  margin-top: 25px;
  margin-bottom: 8px;
  padding-left: 24px;
}

.dot {
  position: absolute;
  left: 38px;
  top: 25px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $red-1;
}
</style>
